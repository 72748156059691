
body {
  width: 100%;
  height: 100%;
}

body, div {
  background-color: #000000;
  margin: 0;
  padding: 0;
}
